import {
  ConfigsPlugin,
  ConfigIds, ConfigItem
} from './interfaces'

export default function getPluginApi (configStore:any) {
  const api: ConfigsPlugin = {
    // ? ________ getters ________
    get configs (): ConfigItem[] {
      return configStore.configs
    },
    get config (): any {
      return configStore.config
    },
    // ? ________ setters ________
    set configs (configs: ConfigItem[]) {
      configStore.setConfigs(configs)
    },
    resetConfigs () {
      configStore.resetConfigs()
    },
    set config (config: any) {
      configStore.setConfig(config)
    },
    resetConfig () {
      configStore.resetConfig()
    },
    // ? ________ actions ________
    getConfigs (): Promise<ConfigItem[]> {
      return configStore.getConfigs()
    },
    getConfig (id: ConfigIds): Promise<any> {
      return configStore.getConfigById(id)
    },
    editConfigs (config: ConfigItem[]): Promise<ConfigItem[]> {
      return configStore.editConfigs(config)
    },
    editConfig (): Promise<any> {
      return configStore.editConfig()
    },
    dropCache (): Promise<any> {
      return configStore.dropCache()
    }
  }
  return api
}
